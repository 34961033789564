@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/Lato-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url("assets/fonts/Lato-Light.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("assets/fonts/Lato-Bold.ttf") format("truetype");
  font-display: swap;
}